import axios from "axios";
import { arc_ngrok_url, payment_url } from "../bootapi";

export const getQuestion = (formdata, token) => {
  return axios.post(`${arc_ngrok_url}/get_questions`, formdata, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getarcAnalysis = (formdata, token, item) => {
  let url = "get_analysis";
  if (item === "natal_chart") {
    url = "natal_chart";
  }
  return axios.post(`${arc_ngrok_url}/${url}`, formdata, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getArcAIAnalysis = (formdata, token) => {
  let url = "ai_analysis";
  return axios.post(`${arc_ngrok_url}/${url}`, formdata, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getCuratedAnalysis = (formdata, token) => {
  return axios.post(`${arc_ngrok_url}/combine_test_analysis`, formdata, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getTestResultAcrossSubject = (type, formdata, token) => {
  return axios.post(`${arc_ngrok_url}/${type}`, formdata, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getPersonalityData = (formdata, token) => {
  return axios.post(`${arc_ngrok_url}/get_user_personality_data`, formdata, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const sendArcMail = (formdata, token) => {
  return axios.post(`${arc_ngrok_url}/send_analysis_mail`, formdata, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getTieBreakerQuestions = (formdata, token) => {
  return axios.post(`${arc_ngrok_url}/tie_breaker`, formdata, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const insertArcData = (data, token) => {
  return axios.post(`${arc_ngrok_url}/insert_seventharc`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const updateArcData = (data, token) => {
  return axios.post(`${arc_ngrok_url}/update_seventharc`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getArcData = (data, token) => {
  return axios.post(`${arc_ngrok_url}/get_seventharc`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const arcUpdateBlurPages = (data, token) => {
  return axios.post(`${arc_ngrok_url}/update_blur_pages`, data, {
    headers: {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getSeventhArcSettings = (user_id, token) => {
  return axios.get(
    `${payment_url}/subsapp/seventharc_settings?user_id=${user_id}`,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};
export const updateSeventhArcSettings = (user_id, id, formdata, token) => {
  return axios.put(
    `${payment_url}/subsapp/seventharc_settings?id=${id}&user_id=${user_id}`,
    formdata,
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};
export const insertSeventhArcSettings = (formdata, token) => {
  return axios.post(`${payment_url}/subsapp/seventharc_settings`, formdata, {
    headers: {
      Authorization: `${token}`,
    },
  });
};